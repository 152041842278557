import { datadogLogs } from "@datadog/browser-logs";
import mixpanel from "mixpanel-browser";
function logEvent(message, messageContext, statusType, error, loggers = { mixpanel: true, datadog: true }) {
  if (loggers.mixpanel) {
    try {
      mixpanel.track(message, messageContext);
    } catch (e) {
      console.error("Mixpanel error", e);
    }
  }
  if (loggers.datadog) {
    try {
      datadogLogs.logger.log(message, messageContext, statusType, error);
    } catch (e) {
      console.error("Datadog error", e);
    }
  }
}
export { logEvent };

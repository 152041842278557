import { useNavigatorLanguage, useStorage } from "@vueuse/core";
import { onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
export function useI18nLocales(preferredAppLocale) {
  const { locale, availableLocales } = useI18n();
  const { language: navigatorLanguage } = useNavigatorLanguage();
  function isLocaleAvailable(locale2) {
    if (!locale2) {
      return false;
    } else {
      const strippedLocale = locale2.replace(/-.*$/, "");
      if (availableLocales.includes(locale2))
        return locale2;
      if (availableLocales.includes(strippedLocale))
        return strippedLocale;
    }
    return false;
  }
  const userSelectedLanguage = useStorage("userSelectedLanguage", "");
  const setLanguage = (locale2) => {
    const validLocale = isLocaleAvailable(locale2);
    const validNavigatorLanguage = isLocaleAvailable(navigatorLanguage.value);
    const validPreferredAppLocale = isLocaleAvailable(preferredAppLocale);
    if (validLocale) {
      userSelectedLanguage.value = validLocale;
    } else if (validNavigatorLanguage) {
      userSelectedLanguage.value = validNavigatorLanguage;
    } else if (validPreferredAppLocale) {
      userSelectedLanguage.value = validPreferredAppLocale;
    } else {
      console.info(`The language ${locale2} is not available. The first available language will be used.`);
      userSelectedLanguage.value = availableLocales[0];
    }
  };
  onMounted(() => {
    setLanguage(userSelectedLanguage.value);
  });
  watch(navigatorLanguage, (newLanguage) => {
    if (newLanguage)
      setLanguage(newLanguage);
  });
  watch(userSelectedLanguage, (newLanguage) => {
    locale.value = newLanguage;
  });
  return {
    locale,
    availableLocales,
    navigatorLanguage,
    userSelectedLanguage,
    setLanguage
  };
}

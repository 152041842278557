import { AvailablePurchaseOptionFixedPriceTicketDtoTicketTypeEnum } from '@peter-park/available-purchase-option'
import { useQuery } from '@tanstack/vue-query'

import type { AvailablePurchaseOptionResponse } from '@peter-park/available-purchase-option'
import type { MaybeRef } from 'vue'

import { useOpenApiClient } from './useOpenApiClient'

//  we can keep the query disabled if areaEntityId is undefined when the composable is instantiated
export function useGetPurchaseOptionsForShop(areaEntityId: MaybeRef<string | undefined>, isEnabled: MaybeRef<boolean> = true) {
  const { apiInstance } = useOpenApiClient()

  async function getPurchaseOptions(areaEntityId: string | undefined): Promise<AvailablePurchaseOptionResponse> {
    if (!areaEntityId)
      return { fixedDurationTickets: [] }

    return await apiInstance.value.availablePurchaseOptionControllerGetAvailablePurchaseOptionsForOnlineShop({ areaEntityId, ticketType: 'ALL' })
  }
  const {
    isLoading,
    isFetching,
    isError,
    isSuccess,
    data,
    error,
    refetch,
    suspense,
  } = useQuery({
    queryKey: ['getPurchaseOptionsForShop', areaEntityId],
    queryFn: () => getPurchaseOptions(unref(areaEntityId)),
    enabled: isEnabled,
    networkMode: 'always',
  })

  const hasMultiVisitTickets = computed(() => {
    return data?.value?.fixedDurationTickets.some(ticket => ticket.ticketType === AvailablePurchaseOptionFixedPriceTicketDtoTicketTypeEnum.multiUse) ?? false
  })

  return {
    getPurchaseOptionsForShopIsLoading: isLoading,
    getPurchaseOptionsForShopIsFetching: isFetching,
    getPurchaseOptionsForShopIsError: isError,
    getPurchaseOptionsForShopIsSuccess: isSuccess,
    getPurchaseOptionsForShopError: error,
    getPurchaseOptionsForShopRefetch: refetch,
    purchaseOptionsForShop: data,
    suspenseGetPurchaseOptionsForShop: suspense,
    hasMultiVisitTickets,
  }
}
